<template>
    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
        <div class="row justify-content-center">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <h1 class="mt-4 font-weight-bolder"><i class="fas fa-plus mr-3"></i>Create New User</h1>
                <hr>
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-5 p-auto">
                        <form @submit.prevent="editPass" class="row p-2">
                            <div class="col-xl-12 form-group mb-3">
                                <label for="">New Password</label>
                                <input v-model="form.new_password" type="password" required class="form-control text-grey-900 font-xsss fw-600">                        
                            </div>
                            <div class="col-xl-12 form-group mb-3">
                                <label for="">Confirmation New Password</label>
                                <input v-model="form.confirmation_password" type="password" required class="form-control text-grey-900 font-xsss fw-600">                        
                            </div>
                            <div class="col-xl-12 form-group text-right my-4">
                                <router-link to="/admin/users" class="btn btn-outline-warning mr-4" value="Submit">Cancel</router-link>
                                <input type="Submit" class="btn btn-current" value="Submit">
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div> 
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: "EditUsersAdmin",
        data(){
            return {
                paramsId: this.$route.params.idUser,
                form: {
                    new_password: '',
                    confirmation_password: '',
                },
                validationErrors: ''
            }
        },
        methods: {
            async editPass(){
                var data = {
                    id: this.paramsId,
                    new_password: this.form.new_password,
                    new_password_confirmation: this.form.confirmation_password,
                }
                await axios.post(`${process.env.VUE_APP_URL_API}/admin/user/password`, data, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.token}`
                    },
                }).then(res => {
                    this.$swal({
                        toast: true,
                        title: 'Success Edit Password !',
                        text: res.data.message,
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                    this.$router.push({ name: 'UsersAdmin' })
                }).catch(err => {
                    if (err.response.status == 422){
                        this.validationErrors = Object.values(err.response.data.errors);
                        // this.$toastr.error(this.validationErrors[0][0], 'failed Create New Course !');
                        this.$swal({
                            toast: true,
                            title: 'Failed Edit Password !',
                            text: this.validationErrors[0][0],
                            icon: 'error',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        })
                    }
                    console.log(err.response)
                })
            }
        }
    }
</script>
